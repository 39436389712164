import React from "react";
import JS from "../images/JS.png";
import React_logo from "../images/React_logo.png";
import MongoDB from "../images/MongoDB.png";
import Express from "../images/Express.png";
import Node from "../images/NodeJS.png";
import Mongoose from "../images/Mongoose.png";
import TS from "../images/ts.png";
import Git from "../images/git.png";
import Sql from "../images/sql.png";
import RB from "../images/ruby.png";
import Rails from "../images/rails.png";


const Skills = () => {
  const logosPro = [
    {
      name: "Git",
      source: Git,
    },
    {
      name: "Ruby",
      source: RB,
    },
    {
      name: "Rails",
      source: Rails,
    },
    {
      name: "SQL",
      source: Sql,
    },
    {
      name: "Javascript",
      source: JS,
    },
    {
      name: "Typescript",
      source: TS,
    },
    {
      name: "React",
      source: React_logo,
    },
  ];

  const logosSolid = [
    {
      name: "Express",
      source: Express,
    },
    {
      name: "Node",
      source: Node,
    },
    {
      name: "MongoDB",
      source: MongoDB,
    },
    {
      name: "Mongoose",
      source: Mongoose,
    },
  ];

  return (
    <div className="components">
      <h1 className="title-component">SKILLS</h1>
      <div class="container">
        <div class="skills-text">Professional experience:</div>
        <div class="row">
          {logosPro.map((logo) => {
            return (
              <div class="col-logos icon-logo" key={logo.key}>
                <img
                  className="logos"
                  src={logo.source}
                  alt={`${logo.name} Logo`}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div class="container">
        <div class="skills-text">Solid knowledge:</div>
        <div class="row">
          {logosSolid.map((logo) => {
            return (
              <div class="col-logos text-logo"key={logo.key}>
                <img
                  className="logos"
                  src={logo.source}
                  alt={`${logo.name} Logo`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Skills;
