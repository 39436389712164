import React from "react";

const Contact = () => {
  return (
    <div className="components" id="contact-me">
      <h1 className="title-component">CONTACT ME</h1>
      <div className="contact-me-container">
        <div className="follow-me-box">
          <h4>
            Hey! Feel free to reach out to me on my social medias: 
          </h4>
          <div className="row social-medias">
            <div class="col">
              <a href="https://www.github.com/marcellits">
                <i class="fa fa-github-square fa-4x" aria-hidden="true" />
              </a>
              <a href="https://www.linkedin.com/in/marcellits/">
                <i class="fa fa-linkedin-square fa-4x" aria-hidden="true" />
              </a>
              <a href="https://twitter.com/_marcellits_">
                <i class="fa fa-twitter-square fa-4x" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
