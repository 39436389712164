import React from "react";
import ProfilePic from "../images/profilePic.jpg";

const Aboutme = () => {
  return (
    <div id="about-me-section" className="section-container">
      <h1 className="title title-component"> ABOUT ME</h1>
      <div className="aboutme">
        <div className="text-aboutme">
          <p>
            <span className="googling">Googling is my favorite sport, </span><br></br>
             I can spend hours searching random topics. History, technology, "DIY" things.
             Maybe that's why I became a software engineer, since searching efficient solutions
             is an inherent attribution for this profession. 😆
          </p>
          <p>
            Joking aside, coding is a daily learning process and it's what I love most.
            Currently, I work at <a href="https://mdlive.com">MDLIVE, an Evernorth Company</a>,
             as Software Engineer, with strong experience in Ruby on Rails on backend and Javascript,
             Typescript plus React on frontend.
          </p>
          <p>
            For more information about my work, take a look at projects section.
          </p>
        </div>
        <div className="pattern-diagonal-lines-sm gray-lighter img-aboutme">
          <div className="pic-card">
            <img style={{ transform: "translate(25px, 25px);" }} className="card-img-top" src={ ProfilePic } alt="bear-say-hi"></img>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Aboutme;